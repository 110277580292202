<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-form class="mx-5" ref="form" lazy-validation>
          <v-row>
            <v-col cols="12">
              <div>
                <v-text-field
                  outlined
                  type="text"
                  label="Name"
                  v-model="certificate.name"
                  :rules="[() => rule(certificate, 'name')]"
                />
              </div>
              <div>
                <v-textarea
                  outlined
                  rows="15"
                  label="Certificate Body"
                  v-model="certificate.html"
                  :rules="[() => rule(certificate, 'html')]"
                ></v-textarea>
              </div>
              <div>
                <template v-if="certificateTemplate">
                  <v-label>Certificate Template</v-label>
                  <div class="template-preview">
                    <v-btn x-small fab @click="changeTemplate"
                      class="template-preview__close">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                    <pdf class="template-preview__pdf"
                      :src="certificateTemplateURL"></pdf>
                  </div>
                </template>
                <v-file-input
                  v-else
                  :prepend-icon="null"
                  outlined
                  label="Certificate Template"
                  accept="application/pdf"
                  v-model="certificate.template"
                  :rules="[() => rule(certificate, 'template')]"
                />
              </div>
              <div class="mt-4 float-right">
                <v-btn
                  color="secondary"
                  class="mr-4"
                  :loading="this.previewing && getCertificatesLoading"
                  :disabled="getCertificatesLoading"
                  @click="preview"
                  >
                  Preview
                </v-btn>
                <v-btn
                  color="primary"
                  :loading="!this.previewing && getCertificatesLoading"
                  :disabled="getCertificatesLoading"
                  @click="submit"
                >
                {{ isCreate ? "Create Certificate" : "Save changes" }}
              </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </base-card>
      <v-dialog v-model="showPreviewDialog">
        <pdf :src="previewContent"></pdf>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HtmlEditor from "@/components/HtmlEditor";
import pdf from "vue-pdf";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  metaInfo: {
    title: "Form"
  },
  components: { TiptapVuetify, HtmlEditor, pdf },
  data() {
    return {
      isCreate: _.get(this.$route, "meta.kind") === "create",
      id: _.get(this.$route, "params.id"),
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      certificate: {
        name: null,
        template: null,
        html: null,
        created_at: null
      },
      previewing: false,
      showPreviewDialog: false,
      previewContent: null,
    };
  },
  mounted: function() {
    if (this.id) {
      this.certificate = _.cloneDeep(this.$store.getters.getCertificate(+this.id));
    }
  },
  methods: {
    ...mapActions(["createCertificate", "updateCertificate", 'previewCertificate']),
    rule(obj, key) {
      return !!_.get(obj, key);
    },
    getPayload() {
      const payload = new FormData();
      _.each(this.certificate, (value, key) => {
        if (value) {
          payload.append(key, value);
        }
      });
      return payload;
    },
    changeTemplate() {
      this.certificate.template = null;
    },
    async preview() {
      this.previewing = true;
      this.$store.dispatch("setCertificatesLoading", true);
      try {
        if (!this.$refs.form.validate()) {
          this.$store.dispatch("showSnackbar", "One or more fields are invalid.");
          return;
        }

        const base64Pdf = await this.previewCertificate(
          this.getPayload()
        );
        this.previewContent = `data:application/df;base64,${base64Pdf}`;
        this.showPreviewDialog = true;
      } catch (error) {
        this.$store.dispatch("showSnackbar", error.data.message);
      }
      this.$store.dispatch("setCertificatesLoading", false);
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }
      const payload = this.getPayload();
      this.$store.dispatch("setCertificatesLoading", true);
      try {
        this.isCreate
          ? await this.createCertificate(payload)
          : await this.updateCertificate({ id: this.id, payload });
        this.$store.dispatch(
          "showSnackbar",
          `${_.startCase(this.certificate.name)} certificate saved.`
        );
        this.$router.push("/app/certificate");
      } catch (error) {
        console.error("DEBUG: error", error);
        this.$store.dispatch("showSnackbar", "Oops, something went wrong!");
        this.$store.dispatch("setPageError", error);
      }

      this.$store.dispatch("setCertificatesLoading", false);
    }
  },
  computed: {
    ...mapGetters(["getCertificatesLoading"]),
    certificateTemplate() {
      return _.get(this.certificate, 'template');
    },
    certificateTemplateURL() {
      if (typeof this.certificateTemplate === 'string') {
        return `${process.env.VUE_APP_API_URL}/storage/template/${this.certificateTemplate}`;
      }
      return window.URL.createObjectURL(this.certificateTemplate);
    },
    pageModel() {
      return _.lowerCase(_.get(this.page, "model"));
    },
    hasPermissions() {
      return !_.isEmpty(this.page.actions) || "This field is required";
    }
  },
  watch: {
    showPreviewDialog(show) {
      if (!show) {
        this.previewing = false;
        this.previewContent = null;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .template-preview {
    position: relative;
    margin-top: 15px;
    border: 1.5px solid #c9c9c9;
    border-radius: 5px;

    .template-preview__close {
      position: absolute;
      top: -10px;
      left: -10px;
      z-index: 10;
    }

    .template-preview__pdf {
      height: 500px;
      overflow: scroll !important;
    }
  }
</style>
